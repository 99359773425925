import { Injectable, Inject } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

import { AppSettings } from './../../app.settings';

@Injectable()
export class UserLogadoService {

  constructor(
    @Inject(SESSION_STORAGE) private _storage: StorageService
  ) {
    
  }

  validarAcesso(tag:String){
    let permitido = false;

    let userClaims = this.getUserLogin()['userToken'].claims;
    let filtrado = userClaims.filter(function(obj){
      return obj['type'] == tag.split('/')[1]
    });
    if (filtrado.length > 0){
      let values = filtrado[0]['value'].split(',');
      values.forEach(e => {
        if (e == 'front-acesso'){
          permitido = true;
          return true;
        }
      });
    }
    return permitido;
  }

  getUserLogin(){
    return JSON.parse(localStorage.getItem(AppSettings.STORAGE_USER));
    //return this._storage.get(AppSettings.STORAGE_USER);
  }

  setUserLogin(user){
    this.removeUserLogin();
    localStorage.setItem(AppSettings.STORAGE_USER, JSON.stringify(user));
    //this._storage.set(AppSettings.STORAGE_USER, user);
  }

  getUserAccessToken(){
    let user = this.getUserLogin();
    if (user){
      return user['accessToken'];
    }
    return null;
  }

  removeUserLogin(){
    localStorage.setItem(AppSettings.STORAGE_USER, '');
    localStorage.removeItem(AppSettings.STORAGE_USER);
    //this._storage.remove(AppSettings.STORAGE_USER);
  }

  getPerfil(){    
    let user = this.getUserLogin()['userToken'];
    
    
    let perfil = {
      id: 0,
      nome: user['nome'],
      sobrenome: user['sobrenome'],
      email: user['email'],
      foto: AppSettings.NO_PHOTO_BASE64,
      refreshToken: this.getUserLogin()['refreshToken'],
      expiresIn: this.getUserLogin()['expiresIn']
    }
    if (user['foto']){
      perfil.foto = user['foto'];
    }
    return perfil;
  }

}
