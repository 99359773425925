import { Injectable, Inject } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { UserLogadoService } from '../user-logado/user-logado.service';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AppSettings } from './../../app.settings';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {

    

    constructor(
        private router: Router,
        
    ) { }

    canActivate() {
        
        if (localStorage.getItem(AppSettings.STORAGE_USER)) {
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }
}
