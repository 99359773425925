import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { AppSettings } from './app.settings';
import { LoginService } from './login/login.service';

import { UserLogadoService } from './shared/user-logado/user-logado.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [LoginService]
})
export class AppComponent {
  title = 'app';

  constructor (
    private _login: LoginService
  ){

  }

  ngOnInit() {
    this._login.renovarToken()
  }

}
