import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AppSettings } from './../app.settings';
import { UserLogadoService } from '../shared/user-logado/user-logado.service';

@Injectable()
export class LoginService {

  constructor(
    private _http: HttpClient,
    private _userLogado: UserLogadoService
  ) { }

  logar(email, senha) {
    let p = {
      'email': email,
      'password': senha
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._http.post(AppSettings.URL_API + '/login', JSON.stringify(p), httpOptions);
  }

  private tempoRenovacaoToken = 15 //segundos
  renovarToken = () => {
    let req: any
    // Se a tentativa de pegar os dados do usuário logado gerar uma exceção,
    // signigica que o usuário não estava logado. Portanto não seguirá em frente
    try {
      req = {
        email: this._userLogado.getPerfil().email,
        refreshToken: this._userLogado.getPerfil().refreshToken
      }
    } catch (err) {
      //console.log(err);
    } finally {
      if (req) {
        if (this.obterTempoRestanteToken() < this.tempoRenovacaoToken) {
          this._http.post(`${AppSettings.URL_API}/refresh`, req, {
            headers: new HttpHeaders().set('Authorization', `bearer ${this._userLogado.getUserAccessToken()}`)
          }).subscribe(
            res => {
              this._userLogado.setUserLogin(res['data']);
              this.iniciarTimerRenovarToken()
            }, err => {
              this.logout()
            }
          )
        } else {
          this.iniciarTimerRenovarToken()
        }
      }
    }
  }

  private obterTempoRestanteToken = () => {
    const expiracao = new Date(this._userLogado.getPerfil().expiresIn)
    const dataAtual = new Date()
    let timeout = Math.abs(expiracao.getTime() - dataAtual.getTime()) / 1000

    return expiracao.getTime() > dataAtual.getTime()
      ? timeout
      : -timeout
  }

  private renovarTokenTimeout
  iniciarTimerRenovarToken = () => {
    const expiracao = new Date(this._userLogado.getPerfil().expiresIn)
    const dataAtual = new Date()
    let timeout = Math.abs(expiracao.getTime() - dataAtual.getTime()) / 1000    
    timeout = (timeout - this.tempoRenovacaoToken) * 1000 // A renovação iniciará antes da expiração do token
    this.renovarTokenTimeout = setTimeout(() => this.renovarToken(), timeout)
  }

  logout = () => {
    this.pararTimerRenovarToken()
    localStorage.clear()
    sessionStorage.clear()
    location.reload()
  }

  private pararTimerRenovarToken = () => {
    clearTimeout(this.renovarTokenTimeout)
  }

}
